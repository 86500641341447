export async function loadConfig() {
  try {
    let r = await fetch('/config.json', { cache: 'default' });
    let c = await r.json();
    return c;
  } catch (error) {
    console.error("Cannot load configuration", error);
  }
}

