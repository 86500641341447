// src/oidc-config.js
import { loadConfig } from "./config";

// load external configuration parameters
const c = await loadConfig();
export const oidcSettings = {
  authority: c.sso.authority,
  client_id: c.sso.client_id,
  redirect_uri: window.location.origin + '/callback',
  silent_redirect_uri: window.location.origin + '/renew',
  automaticSilentRenew: true,
  silentRequestTimeout: 10000,
  response_type: 'code',
  scope: 'openid profile email',
  post_logout_redirect_uri: c.sso.post_logout_redirect_uri,
};