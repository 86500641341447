import { ref } from 'vue';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc);
import { TOOLBOX_ITEMS, TIMELINE_TASKS, DATE_FORMAT, BAR_CATEGORIES, DB_DATE_FORMAT, DATE_ONLY_FORMAT, PRELOAD_DAYS_BUFFER } from '../utils/metadata';
import { uniformDate } from '../utils/utils';
import { getAllCatalogData, getAllTasks, setChangeListener, saveTask, deleteTask, saveOrDeleteTasks, getUuids } from '@/utils/dataSource';
import { getCurrentMovedBars } from '@/utils/utils';

/*-------------------- MODELS --------------------*/


const timelineModel = ref([]);
const toolboxModel = ref([]);
const ganttPreviewModel = ref([]);

const catData = {
  order  : [],
  vehicle: [],
  driver : []
}
const otherCatData = {
  repair : []
}
const taskMap = new Map();
const taskIdVsRoad = new Map();

// Helpers
let orders, drivers, vehicles, repairs, tasks;

let catIndex = 0;
const catTypes = new Map([
  [catIndex++, 'order'],
  [catIndex++, 'vehicle'],
  [catIndex++, 'driver']
]);
const catTypesArray = Array.from(catTypes.values());

const catItemMaps = {
  order: null,
  vehicle: null,
  driver: null
}

const otherCatTypes = new Map([
  [catIndex++, 'repair']
]);

const otherMaps = {
  trailerLoaded: null,
  repair: null,
}

const idVsModelTask = new Map();

function setCatItemMaps(timelineStart) {
  catItemMaps.vehicle = new Map();
  catItemMaps.driver = new Map();
  catItemMaps.order = new Map();
  otherMaps.repair = new Map();

  orders.forEach(elem => {
    elem.availSince = dayjs(elem.source_after, DB_DATE_FORMAT).format(DATE_FORMAT);
    catItemMaps.order.set("" + elem.Id, elem);
  });
  vehicles.forEach(elem => {
    elem.availSince =timelineStart;
    catItemMaps.vehicle.set("" + elem.Id, elem);
  });
  drivers.forEach(elem => {
    elem.availSince = timelineStart;
    catItemMaps.driver.set("" + elem.Id, elem);
  });

  repairs.forEach(elem => {
    elem.availSince = dayjs(elem.after, DATE_ONLY_FORMAT).format(DATE_FORMAT);
    otherMaps.repair.set("" + elem.Id, elem);
  });
}

function setTaskMaps() {
  idVsModelTask.clear();
  tasks.forEach(elem => {
    const isCatalogType = catTypesArray.some(type => type === elem.cat);
    let extended_name = '';
    if (!isCatalogType) {
      catTypes.forEach(type => {
        if (elem[type + '_id']) {
          if (extended_name) {
            extended_name += ', ';
          }
          extended_name += catItemMaps[type].get(elem[type + '_id'])?.name;
        }
      });
      otherCatTypes.forEach(type => {
        if (elem[type + '_id']) {
          if (extended_name) {
            extended_name += ', ';
          }
          extended_name += otherMaps[type].get(elem[type + '_id'])?.name;
        }
      });
    }
    const name = (isCatalogType ? catItemMaps[elem.cat].get(elem[elem.cat + '_id'])?.name : extended_name) || '';
    const catType = (isCatalogType ? catItemMaps[elem.cat].get(elem[elem.cat + '_id'])?.type : null) || '';
    const task = {
      id        : elem._id,
      createdAt : elem.created_at,
      start     : elem.after,
      end       : elem.until,
      name,
      title     : name + (elem.type ? (name ? ': ' : '') + elem.type : ''),
      catType,
      type      : elem.type,
      cat     :   elem.cat,
      vehicleId : elem.vehicle_id,
      driverId  : elem.driver_id,
      orderId   : elem.order_id,
      courseId  : elem.course_id,
      repairId  : elem.repair_id
    };
    idVsModelTask.set(elem._id, task);
  });
}

function preprocessCatItemsData(timelineStart) {
  catTypes.forEach(cat => {
    catItemMaps[cat].forEach(catItem => {
      if (cat === 'order') {
        catItem.loadScheduled = null;
        catItem.unloadScheduled = null;
      }
    });
  });
  otherCatTypes.forEach(cat => {
    otherMaps[cat].forEach(catItem => {
      if (cat === 'repair') {
        catItem.scheduled = null;
      }
    });
  });
  const orderIdVsLoadActions = new Map();
  idVsModelTask.forEach(task => {
    if (task.repairId) {
      const repairCatItem = otherMaps.repair.get(task.repairId);
      console.log('task.repairId', task.repairId, repairCatItem);
      if (repairCatItem) {
        repairCatItem.scheduled = true;
      }
    }
    catTypes.forEach(cat => {
      const catItem = catItemMaps[cat].get(task[cat + 'Id']);
      if (catItem) {
        if (!catItem.availSince) {
          catItem.availSince = dayjs(timelineStart, DATE_FORMAT);
        }
        const taskEnd = dayjs(task.end, DB_DATE_FORMAT).format(DATE_FORMAT);
        if (catItem.availSince < taskEnd) {
          catItem.availSince = taskEnd;
        }
      }
    });
    if (task.cat === 'action' && task.vehicleId && task.orderId) {
      const vehicle = catItemMaps.vehicle.get(task.vehicleId);
      const order = catItemMaps.order.get(task.orderId);
      if (vehicle && order) {
        if (task.type === 'load') {
          order.loadScheduled = true;
          if (vehicle.type === 'trailer' || vehicle.type === 'semi-trailer') {
            let actions = orderIdVsLoadActions.get(order.Id);
            if (!actions) {
              actions = [];
              orderIdVsLoadActions.set(order.Id, actions);
            }
            if (!actions.find(action => action.id === task.id))
            {
              actions.push(task);
            }
          }
        }
        else
        if (task.type === 'unload') {
          order.unloadScheduled = true;
          if (vehicle.type === 'trailer' || vehicle.type === 'semi-trailer') {
            let actions = orderIdVsLoadActions.get(order.Id);
            if (!actions) {
              actions = [];
              orderIdVsLoadActions.set(order.Id, actions);
            }
            if (actions.find(action => action.type === 'load')) {
              orderIdVsLoadActions.delete(order.Id);
            }
            else
            if (!actions.find(action => action.id === task.id))
            {
              actions.push(task);
            }
          }
        }
      }
    }

  });
  console.log('+++++orderIdVsLoadActions', orderIdVsLoadActions);
  otherMaps.trailerLoaded = new Map();
  orderIdVsLoadActions.forEach(actions => {
    actions.forEach(action => {
      if (action.type === 'load') {
        const vehicle = catItemMaps.vehicle.get(action.vehicleId);
        const order = catItemMaps.order.get(action.orderId);
        otherMaps.trailerLoaded.set(vehicle.Id, {
          ...vehicle,
          name: vehicle.name + ': ' + order.name
        });
      }
    });
  });
}

// Actions

const actionMaps = {
  action: new Map([
    ['load', {
      id:    'load',
      name: 'Load',
      cat: 'action',
      type: 'load'
    }],
    ['transport', {
      id:    'transport',
      name: 'Transport',
      cat: 'action',
      type: 'transport'
    }],
    ['unload', {
      id:    'unload',
      name: 'Unload',
      cat: 'action',
      type: 'unload'
    }],
    ['empty', {
      id:    'empty',
      name: 'Empty',
      cat: 'action',
      type: 'empty'
    }]
  ])
};

// Toolbox

function buildToolboxModel() {
  const toolboxMaps = Object.assign({}, catItemMaps, otherMaps, actionMaps);
  toolboxModel.value = Object.keys(toolboxMaps).map(key => {
    const item = toolboxMaps[key];
    let bars = [];
    const groupId = 'toolbox_group_' + key;
    let metaCategory = TOOLBOX_ITEMS.find(elem => elem.category === key) || {};
    if (item) {
      bars = Array.from(item.values()).map(bar => {
        const name = key === 'repair' && bar.vehicle?.name && !bar.name.startsWith(bar.vehicle?.name) ? (bar.vehicle?.name + ' ') + bar.name : bar.name;
        const metaType = (bar.type ? TOOLBOX_ITEMS.find(elem => elem.type === bar.type && elem.category === key) : null) || metaCategory;
        let nbar = {
          ...bar,
          _id:   bar.id || bar.Id,
          id:    groupId + '_' + (bar.id || bar.Id),
          title: name,
          oldCategory: key,
          category: metaType.lane || key,
          color: metaType.color,
          icon:  metaType.icon,
          oldType: bar.type || 'transport',
          type: (key === 'action' ? bar.type : 'transport')
        }
        if (metaType.category === 'repair') {
          nbar.type = 'repair';
        }
        return nbar;
      });
    }
    return {
      id:       groupId,
      title:    key,
      category: key,
      color:    metaCategory.color,
      icon:     metaCategory.icon,
      bars
    }
  });
  console.log('toolboxModel', toolboxModel.value);
}

// Timeline

let courseIdVsTasks;

const vehicleTypeVsOrder = new Map();
vehicleTypeVsOrder.set('truck', 1);
vehicleTypeVsOrder.set('semi-truck', 2);
vehicleTypeVsOrder.set('trailer', 3);
vehicleTypeVsOrder.set('semi-trailer', 4);

function sortTasks(a, b) {
  if (a.courseIdInternal === b.courseIdInternal) {
    return 0;
  }
  const bundleA = courseIdVsTasks.get(a.courseIdInternal);
  const bundleB = courseIdVsTasks.get(b.courseIdInternal);
  if (bundleA.min < bundleB.min) {
    return -1;
  }
  else
  if (bundleA.min > bundleB.min) {
    return 1;
  }
  else
  if (bundleA.createdAt < bundleB.createdAt) {
    return -1;
  }
  else
  if (bundleA.createdAt > bundleB.createdAt) {
    return 1;
  }
  else {
    return 0;
  }
};

function sortTasks2(a, b) {
  if (a.cat < b.cat) {
    return -1;
  }
  else
  if (a.cat > b.cat) {
    return 1;
  }
  else
  if (a.cat === b.cat && b.cat === 'order' && a.orderId && b.orderId) {
    if (a.start < b.start) {
      return -1;
    }
    else if (a.start > b.start) {
      return 1;
    }
    else
    if (a.name < b.name) {
      return -1;
    }
    else
    if (a.name > b.name) {
      return 1;
    }
    else
    if (a.createdAt < b.createdAt) {
      return -1;
    }
    else
    if (a.createdAt > b.cleatedAt) {
      return 1;
    }
    else {
      return 0;
    }
  }
  else
  if (a.cat === b.cat && b.cat === 'vehicle' && a.vehicleId && b.vehicleId) {
    const av = catItemMaps.vehicle.get(a.vehicleId) || {};
    const bv = catItemMaps.vehicle.get(b.vehicleId) || {};
    const at = vehicleTypeVsOrder.get(av.type) || 0;
    const bt = vehicleTypeVsOrder.get(bv.type) || 0;
    if (at < bt) {
      return -1;
    }
    else
    if (at > bt) {
      return 1;
    }
    if (a.start < b.start) {
      return -1;
    }
    else if (a.start > b.start) {
      return 1;
    }
    else
    if (a.createdAt < b.createdAt) {
      return -1;
    }
    else
    if (a.createdAt > b.cleatedAt) {
      return 1;
    }
    else
    if (a.name < b.name) {
      return -1;
    }
    else
    if (a.name > b.name) {
      return 1;
    }
    else {
      return 0;
    }
  }
  else
  if (a.cat === b.cat && b.cat === 'driver' && a.driverId && b.driverId) {
    if (a.name < b.name) {
      return -1;
    }
    else
    if (a.name > b.name) {
      return 1;
    }
    else
    if (a.start < b.start) {
      return -1;
    }
    else
    if (a.start > b.start) {
      return 1;
    }
    else
    if (a.createdAt < b.createdAt) {
      return -1;
    }
    else
    if (a.createdAt > b.cleatedAt) {
      return 1;
    }
    else {
      return 0;
    }
  }
  else
  if (a.start < b.start) {
    return -1;
  }
  else
  if (a.start > b.start) {
    return 1;
  }
  else
  if (a.createdAt < b.createdAt) {
    return -1;
  }
  else
  if (a.createdAt > b.cleatedAt) {
    return 1;
  }
  else
  if (a.name < b.name) {
    return -1;
  }
  else
  if (a.name > b.name) {
    return 1;
  }
  else {
    console.log('35');
    return 0;
  }
};

const laneTypes = ['order' , 'vehicle', 'driver', 'action'];

function addRoad(road, tmodel, rowId) {
  let lane = 1;
  laneTypes.forEach(type => {
    let typeLanes = road?.laneMatrix.get(type);
    if (typeLanes) {
      typeLanes.forEach(lanes => {
        const bars = [];
        tmodel.push({
          id: rowId++,
          type,
          lane,
          road: road?.road || 1,
          bars
        });
        const previewBars = [];
        previewBars.lane = lane;
        previewBars.road = road?.road || 1;
        if (!lensMode) {
          ganttPreviewModel.value.push(previewBars);
        }
        lanes.forEach(tsk => {
          tsk.lane = lane;
          bars.push(tsk);
          if (!lensMode) {
            previewBars.push({
              id: tsk.id,
              start: new Date(tsk.start),
              end: new Date(tsk.end),
              title: tsk.title,
              dependencies: [],
              completed: 0,
              road: tsk.road,
              lane: tsk.lane
            });
          }
        });
        lane++;
      });
    }
    else {
      tmodel.push({
        id: rowId++,
        type,
        lane,
        road: road?.road || 1,
        bars: []
      });
      const previewBars = [];
      previewBars.lane = lane;
      previewBars.road = road?.road || 1;
      if (!lensMode) {
        ganttPreviewModel.value.push(previewBars);
      }
      lane++;
    }
  });
  return rowId;
}

function prebuildTimelineModel(tmodel) {
  const minDate = (dates) => dates.sort((a, b) => a < b ? -1 : (a == b ? 0 : 1))[0];
  const maxDate = (dates) => dates.sort((a, b) => a < b ? -1 : (a == b ? 0 : 1))[dates.length-1];

  const modelTasks = Array.from(idVsModelTask.values());

  courseIdVsTasks = new Map();

  modelTasks.forEach(task => {
    let courseId = task.courseId;
    if (!courseId) {
      courseId = 'new-' + crypto.randomUUID();
    }
    task.courseIdInternal = courseId;
    let bundle = courseIdVsTasks.get(courseId);
    if (!bundle) {
      bundle = {
        courseId,
        taskIds: [],
        tasks: [],
        taskDates: [],
        taskCreatedAt: []
      };
      courseIdVsTasks.set(courseId, bundle);
    }
    bundle.taskIds.push(task.id);
    bundle.tasks.push(task);
    bundle.taskDates.push(task.start);
    bundle.taskDates.push(task.end);
    bundle.taskCreatedAt.push(task.createdAt);
  });
  courseIdVsTasks.forEach(bundle => {
    bundle.min = minDate(bundle.taskDates);
    bundle.max = maxDate(bundle.taskDates);
    bundle.createdAt = minDate(bundle.taskCreatedAt);
    delete bundle.taskDates;
  });

  const roadMatrix = [];
  const addedTaskIds = new Set();

  const sortedTasks = Array.from(idVsModelTask.values()).sort(sortTasks);

  sortedTasks.forEach((task) => {
    if (addedTaskIds.has(task.id)) {
      return;
    }
    const courseId = task.courseId;
    let tsk = courseIdVsTasks.get(courseId);
    if (!tsk) {
      task.course_id = null;
      tsk = {
        taskIds: [task.id],
        tasks: [task],
        min: task.start,
        max: task.end
      }
    }
    let added = false;
    for (let i = 0; i < roadMatrix.length; i++) {
      let rows = roadMatrix[i];
      if (!rows.some(elem => tsk.min < elem.max && tsk.max > elem.min)) {
        rows.push(tsk);
        added = true;
        break;
      }
    }
    if (!added) {
      roadMatrix.push([tsk]);
    }
    tsk.taskIds.forEach(id => addedTaskIds.add(id));
  });

  let road = 1;

  roadMatrix.forEach(rows => {
    rows.laneMatrix = new Map();
    rows.road = road;
    rows.forEach(elem => {
      elem.tasks.sort(sortTasks2);
      elem.tasks.forEach(tsk => {
        tsk.road = road;
        taskIdVsRoad.set(tsk.id, road);

        let laneType = tsk.cat;
        if (!laneTypes.some(elem => elem === laneType)) {
          laneType = 'other';
        }
        let lanes = rows.laneMatrix.get(laneType);
        if (!lanes) {
          lanes = [];
          rows.laneMatrix.set(laneType, lanes);
        }
        let added = false;
        for (let i = 0; i < lanes.length; i++) {
          const laneEvts = lanes[i];
          if (!laneEvts.some(elem => tsk.start < elem.end && tsk.end > elem.start)) {
            laneEvts.push(tsk);
            added = true;
            break;
          }
        }
        if (!added) {
          lanes.push([tsk]);
        }
      });
    });
    road++;
  });

  if (!lensMode) {
    ganttPreviewModel.value = [];
  }

  let rowId = 1;

  roadMatrix.forEach(road => {
    rowId = addRoad(road, tmodel, rowId);
  });
  if (!tmodel.length) {
    rowId = addRoad(null, tmodel, rowId);
  }
}

function buildTimelineModel(timelineModelWork) {
  timelineModel.value = timelineModelWork.map(row => {
    const metaCategory = BAR_CATEGORIES.find(elem => elem.category === row.type) || {};
    let nrow = {
      id:    'timeline_row_' + row.id,
      category: row.type,
      title: metaCategory.category,
      color: metaCategory.color,
      icon:  metaCategory.icon,
      //
      lane:  row.lane,
      road:  row.road,

      bars:  row.bars.map((bar, index) => {
        let metaType = TIMELINE_TASKS.find(elem => {
          if (bar.cat === 'vehicle' && bar.type === 'transport') {
            return elem.catType === bar.catType;
          }
          else {
            return elem.type === bar.type && elem.category === bar.cat;
          }
        }) || metaCategory;

        let   nbar = {
          ...bar,
          start: uniformDate( bar.start ),
          end:   uniformDate( bar.end ),
          ganttBarConfig: {
            id:           'timeline_row_' + row.id + '_' + bar.id,
            immobile:    bar.completed,
            hasHandles: !bar.completed,
            // bundle:     bar.courseId,
          },
          category:    bar.cat,
          color:       metaType.color,
          icon:        metaType.icon,
          completed:   bar.completed
        };
        return nbar;
      })
    }
    return nrow;
  });
}

// Init
function initModel(timelineStart, timelineEnd, data) {
  orders = data.orders;
  drivers = data.drivers;
  vehicles = data.vehicles;
  repairs = data.repairs;
  tasks = data.tasks;
  setCatItemMaps(timelineStart);
  setTaskMaps();
  preprocessCatItemsData(timelineStart);
  console.log('====initModel', timelineStart, timelineEnd, orders, drivers, vehicles, repairs, tasks);

  const timelineModelWork = [];
  prebuildTimelineModel(timelineModelWork);

  buildTimelineModel(timelineModelWork);
  buildToolboxModel();
}

function initModelInternal(timelineStart, timelineEnd) {
  const taskData = Array.from(taskMap.values());

  initModel(timelineStart, timelineEnd, {
    orders: catData.order, drivers: catData.driver, vehicles: catData.vehicle, repairs: otherCatData.repair, tasks: taskData
  });
}

// Load data

let changesSince = 'now';

function loadData(timelineStart, timelineEnd) {
  console.log('loadData', timelineStart, timelineEnd);
  // setChangeListener(null);
  catData.order = [];
  catData.vehicle = [];
  catData.driver = [];
  otherCatData.repair = [];
  taskMap.clear();
  taskIdVsRoad.clear();

  //------------------- Load data

  const after = dayjs(timelineStart, DATE_FORMAT).utc().format(DB_DATE_FORMAT);
  const until = dayjs(timelineEnd, DATE_FORMAT).utc().format(DB_DATE_FORMAT);

  const catPromises = [];

  catTypes.forEach(type => {
    catPromises.push(getAllCatalogData(type));
  });

  const query = '(until,ge,exactDate,'
    + dayjs(timelineStart, DATE_FORMAT).format('YYYY-MM-DD')
    + ')~and(after,le,exactDate,'
    + dayjs(timelineEnd, DATE_FORMAT).add(2, 'weeks').format('YYYY-MM-DD')
    + ')';
  otherCatTypes.forEach(type => {
    catPromises.push(getAllCatalogData(type, query));
  });

  Promise.all(catPromises)
  .then(results => {

    setChangeListener((changes, since) => {
      console.log('====changes, since', changes, since);
      changesSince = since;
      changes.forEach(task => {
        if (task._deleted) {
          taskMap.delete(task._id);
        }
        else {
          taskMap.set(task._id, task);
        }
      });

      const bars = getCurrentMovedBars();
      if (bars?.length) {
        bars.forEach(bar => {
          const tsk = taskMap.get(bar.id);
          if (tsk) {//restore after/until of currently moved bars
            tsk.after = dayjs(bar.start, DATE_FORMAT).utc().format(DB_DATE_FORMAT);
            tsk.until = dayjs(bar.end  , DATE_FORMAT).utc().format(DB_DATE_FORMAT);
          }
        });
      }
      initModelInternal(timelineStart, timelineEnd);
    }, changesSince);

    results.forEach((res, idx) => {
      let type = catTypes.get(idx);
      if (type) {
        catData[type] = res;
      }
      else {
        type = otherCatTypes.get(idx);
        if (type) {
          otherCatData[type] = res;
        }
      }
    });

    const taskPromises = [];
    catTypes.forEach(type => {
      if (!catData[type] || catData[type].length == 0) {
        return;
      }

      catData[type].forEach(item => {
        taskPromises.push(getAllTasks(
          type + '_id',
          item.Id,
          type,
          after,
          until)
        );
      });
    });

    Promise.all(taskPromises)
    .then(taskResults => {
      const courseIds = new Set();
      const driverIdVsTasks = new Map();
      const courseIdVsActions = new Map();
      taskResults.forEach(taskRes => {
        if (taskRes.length && taskRes[0].cat === 'driver' && taskRes[0].driver_id) {
          driverIdVsTasks.set(taskRes[0].driver_id, taskRes);
        }
        taskRes.forEach(task => {
          taskMap.set(task._id, task);
          if (task.course_id) {
            courseIds.add(task.course_id);
          }
        });
      });
      if (courseIds.size) {
        const coursePromises = [];
        courseIds.forEach(courseId => {
          coursePromises.push(getAllTasks(
            'course_id',
            courseId,
            'action',
            after,
            until)
          );
        });
        console.log('coursePromises', coursePromises);
        Promise.all(coursePromises)
        .then(actionResults => {
          actionResults.forEach(actionRes => {
            if (actionRes.length && actionRes[0].course_id) {
              courseIdVsActions.set(actionRes[0].course_id, actionRes);
            }
            actionRes.forEach(action => {
              taskMap.set(action._id, action);
            });
          });
          console.log('====driverIdVsTasks', driverIdVsTasks);
          console.log('====courseIdVsActions', courseIdVsActions);
          //TODO schedule for each driver
          initModelInternal(timelineStart, timelineEnd);
        })
        .catch(actionError => {
          console.log('Action data error', actionError);
        });
      }
      else {
        initModelInternal(timelineStart, timelineEnd);
      }
    })
    .catch(taskError => {
      console.log('Task data error', taskError);
    });
  })
  .catch(error => {
    console.log('Catalog data error', error);
  });
}

export function useModels(timelineStart, timelineEnd, lens/*, orders, drivers, vehicles, tasks*/) {
  lensMode = lens;

  const timelineStartBuffered = dayjs(timelineStart, DATE_FORMAT).add(-PRELOAD_DAYS_BUFFER, 'day').format(DATE_FORMAT);
  const timelineEndBuffered   = dayjs(timelineEnd,   DATE_FORMAT).add( PRELOAD_DAYS_BUFFER, 'day').format(DATE_FORMAT);

  loadData(timelineStartBuffered, timelineEndBuffered);

  return {
    timelineModel,
    toolboxModel,
    ganttPreviewModel
  }
}

let lensMode = false;

export function useLens({date, road}) {
  lensMode = true;
  const previewStart = dayjs(date).startOf('day');
  const previewEnd = previewStart.add(1, 'day');
  let found = false;
  timelineModel.value.forEach(row => {
    if (!found && road === row.road) {
      found = true;
      const el = document.getElementById(row.id);
      if (el) {
        el.scrollIntoView({behavior: "smooth", block: "center" });
      }
    }
  });

  return {
    previewStart: previewStart.format(DATE_FORMAT),
    previewEnd: previewEnd.format(DATE_FORMAT)
  }
}

export function resetLens() {
  lensMode = false;
}

function str(val) {
  return !val && val !== 0 ? null : '' + val;
}

async function prepareTasksForUpdate(tasks, isDelete) {
  const docs = [];
  let singleCount = 0;
  const groupVsUuid = new Map();
  if (!tasks?.length) {
    console.log('No tasks specified');
    return [];
  }
  tasks.forEach(task => {
    let doc = taskMap.get(task.id);
    if (isDelete) {
      if (doc) {
        doc._deleted = true;
        task.ghost = true;
        docs.push(doc);
      }
      else {
        console.log('Task not found, skip delete', task);
      }
    }
    else {
      if (!doc) {
        console.log('Task not found, creating a new', task);
        doc = {
          created_at: dayjs().utc().format(DB_DATE_FORMAT),
          cat       : task.cat,
          type      : task.type,
          vehicle_id: str(task.vehicleId),
          driver_id : str(task.driverId),
          order_id  : str(task.orderId),
          repair_id : str(task.repairId),
          course_id : str(task.courseId)
        }
      }
      task.ghost = true;
      const after = dayjs(task.start, DATE_FORMAT).utc().format(DB_DATE_FORMAT);
      const until = dayjs(task.end, DATE_FORMAT).utc().format(DB_DATE_FORMAT);
      doc.after = after;
      doc.until = until;
      if (!task.courseId) {
        if (task.courseGroup) {
          groupVsUuid.set(task.courseGroup, null);
        }
        else {
          singleCount++;
        }
      }
      docs.push(doc);
    }
  });
  const count = singleCount + groupVsUuid.size;
  if (count) {
    const uuids = await getUuids(count);
    if (groupVsUuid.size) {
      Array.from(groupVsUuid.keys()).forEach(key => {
        groupVsUuid.set(key, uuids.splice(0, 1)[0]);
      });
    }
    tasks.forEach((task, index) => {
      const doc = docs[index];
      if (!doc.course_id) {
        if (task.courseGroup) {
          doc.course_id = groupVsUuid.get(task.courseGroup);
        }
        else {
          doc.course_id =  uuids.splice(0, 1)[0];
        }
      }
    });
  }
  return docs;
}

export function upsertTask(task) {
  if (!task) {
    console.log('upsertTask: no task supplied');
    return;
  }

  prepareTasksForUpdate([task])
  .then(docs => {
    saveTask(docs[0])
    .then(res => {
      console.log('upsert ok', task);
    })
    .catch(err => {
      console.log('upsert error', err, task);
    });
  })
  .catch(err => {
    console.log('upsert error2', err, task);
  });
}

export function removeTask(task) {
  const doc = taskMap.get(task?.id);
  if (!doc || !doc._rev) {
    console.log('removeTask: task not found or have no revision', task, doc);
    return;
  }

  task.ghost = true;
  deleteTask(doc)
  .then(res => {
    console.log('delete ok', task);
  })
  .catch(err => {
    console.log('delete error', err, task);
  });
}

export function updateOrDeleteTasks(tasks, isDelete) {
  if (!tasks?.length) {
    console.log('updateTasks: no tasks supplied');
    return;
  }

  prepareTasksForUpdate(tasks, isDelete)
  .then(docs => {
    saveOrDeleteTasks(docs)
    .then(res => {
      console.log('saveOrDelete ok', tasks);
    })
    .catch(err => {
      console.log('saveOrDelete error', err, tasks);
    });
  })
  .catch(err => {
    console.log('saveOrDelete error2', err, tasks);
  });
}