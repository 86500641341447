import { ref } from 'vue';
import dayjs from 'dayjs';
import { DATE_FORMAT } from '@/utils/metadata';
import { updateOrDeleteTasks } from '@/utils/useModels';
import useTimePositionMapping from '@/composables/useTimePositionMapping';
import * as vtcolors from 'vuetify/lib/util/colors.mjs';
import vuetify from '@/plugins/vuetify';

// Data formats

export function uniformDate(date) {
  if (!date) {
    return null;
  }
  return dayjs(date).format(DATE_FORMAT);
}

export function humanDate(date, outputFormat, inputFormat) {
  const iformat = inputFormat || DATE_FORMAT;
  let oformat = 'ddd, DD MMM YYYY HH:mm';
  if (outputFormat === 'short') {
    oformat = 'DD MMM HH:mm';
  }
  else
  if (outputFormat === 'dotsShortYearNoTime') {
    oformat = 'DD.MM.YY';
  }
  return dayjs(date, iformat).format(oformat);
}

// Colors

function changeCaseKebapToCamel(s) {
  return s.replace(/-./g, x=>x[1].toUpperCase());
}

export function colorToCssColor(color) {
  if (typeof color !== "string" || color.trim().length === 0) {
    return color;
  }
  if (color.startsWith("#") || color.startsWith("rgb")) {
    return color;
  }
  const themeColors = vuetify.theme.current.value.colors;
  const stringToColor = new Map([
    ["black", vtcolors.shades.black],
    ["white", vtcolors.shades.white],
    ["transparent", vtcolors.shades.transparent],
  ]);
  const regularColors = [
    "red",
    "pink",
    "purple",
    "deepPurple",
    "indigo",
    "blue",
    "lightBlue",
    "cyan",
    "teal",
    "green",
    "lightGreen",
    "lime",
    "yellow",
    "amber",
    "orange",
    "deepOrange",
    "brown",
    "blueGrey",
    "grey",
  ];
  regularColors.forEach((regularColor) => {
    stringToColor.set(regularColor, vtcolors[regularColor].base);
    stringToColor.set(
      `${regularColor}-lighten-1`,
      vtcolors[regularColor].lighten5
    );
    stringToColor.set(
      `${regularColor}-lighten-2`,
      vtcolors[regularColor].lighten4
    );
    stringToColor.set(
      `${regularColor}-lighten-3`,
      vtcolors[regularColor].lighten3
    );
    stringToColor.set(
      `${regularColor}-lighten-4`,
      vtcolors[regularColor].lighten2
    );
    stringToColor.set(
      `${regularColor}-lighten-5`,
      vtcolors[regularColor].lighten1
    );
    stringToColor.set(`${regularColor}-darken-6`, vtcolors[regularColor].darken1);
    stringToColor.set(`${regularColor}-darken-7`, vtcolors[regularColor].darken2);
    stringToColor.set(`${regularColor}-darken-8`, vtcolors[regularColor].darken3);
    stringToColor.set(`${regularColor}-darken-9`, vtcolors[regularColor].darken4);
  });
  for (const key in themeColors) {
    if (!key.startsWith("on-")) {
      stringToColor.set(key, themeColors[key]);
    }
  }
  const val = stringToColor.get(changeCaseKebapToCamel(color));
  if (val) {
    return val;
  }
  return "";
}

// Timeline bars format/style

export function barClasses(bar) {
  let classes = [
    'bg-' + bar.color
  ];
  if (bar.completed) {
    classes.push('completed', 'opacity-20');
  }
  else {
    if (bar.ghost) {
      classes.push('ghost', 'opacity-50');
    }
    if (bar.planned) {
      classes.push('planned', 'opacity-50');
    }
    if (bar.selected) {
      classes.push('selected', 'border-dashed border-surface-bright');
    }
    if (bar.invalid || bar.error) {
      classes.push('invalid', 'error', 'border-solid border-error');
    }
    if (bar.warning) {
      classes.push('invalid', 'warning', 'border-solid border-warning');
    }
  }

  return classes;
}
export function barStyles(bar) {
  let style = {};
  if (bar.category === 'driver' && bar.drivingPattern) {
    const { mapMinsToPixels } = useTimePositionMapping();
    style = drivingPatternBgr(bar.drivingPattern.map(mins => mapMinsToPixels(mins)));
  }
  return style;
}
export function rowClasses(row) {
  let classes = [
    'bg-' + getBgColor(row.color)
  ];
  return classes;
}
let road = 0;
export function rowStyles(row) {
  let styles = [];

  if (row.road !== 1 && road !== row.road) {
    styles.push(`
      box-sizing: content-box;
      border-block-start-width: thin !important;
      border-block-start-style: solid !important
      `);
  }
  road = row.road;
  return styles;
}
export function getValueIcon(val) {
  return val ? 'mdi-check' : 'mdi-close';
}

// Metadata calcs

export function getBgColor(color) {
  return color + '-lighten-4';
}
// TODO: temporary until write conversion logic
const colors = [
  { color: 'blue',   colorHex: '#2196f3', bgcolorHex: '#bbdefb' },
  { color: 'green',  colorHex: '#4caf50', bgcolorHex: '#c8e6c9' },
  { color: 'orange', colorHex: '#ff9800', bgcolorHex: '#ffe0b2' }
]
export function getHexColor(color) {
  return colors.find(elem => elem.color === color)?.colorHex;
}
export function getHexBgColor(color) {
  return colors.find(elem => elem.color === color)?.bgcolorHex;
}

// Driving pattern

/**
 * Build a repeating two-color pattern, using CSS linear-gradient background from the:
 * @param {array} arr - array of pixel numbers
 * @param {string} color (1) - driving; if omitted, a half-transparent white will be used `#fff4`
 * @param {string} color2 - rest; defaults to fully 'transparent'
 * @returns an object with CSS rules
 */
export function drivingPatternBgr(arr, col, col2) {
  if (!arr || !Array.isArray(arr)) {
    return arr;
  }

  const color1 = col  || '#fff4';
  const color2 = col2 || 'transparent';

  let bgr = 'linear-gradient(90deg';
  arr.forEach((pos, index) => {
    bgr += ', ' + (index % 2 ? color1 : color2) + ' ' + pos + 'px';
    bgr += ', ' + (index % 2 ? color2 : color1) + ' ' + pos + 'px';
  });
  bgr += ')';

  return {
    backgroundImage: bgr
  }
}

// useHelpers

const THRESHOLD_X = 20;

export function useDragThreshold() {

  const initialEvent = ref(null);
  const oncePass = ref(false);

  const ignoreDrag = (mouseXNew) => {
    return oncePass.value || Math.abs(mouseXNew - initialEvent.value.clientX) < THRESHOLD_X;
  }

  return {
    initialEvent,
    oncePass,
    ignoreDrag
  }
}

// Load/Save

const movedBarsInDrag = ref([]);

export function getCurrentMovedBars() {
  return movedBarsInDrag.value;
}

export function addToMovedBarsInDrag(bar) {
  movedBarsInDrag.value.push(bar);
}

export function onBarEndDrag(e) {
  // console.log('onBarEndDrag', movedBarsInDrag.value, e);
  updateOrDeleteTasks(movedBarsInDrag.value);
  movedBarsInDrag.value = [];
}