import { getAccessToken, userManager } from './oidc-client';
import { useRouter } from 'vue-router';

export async function apiCall(url, options = {}) {
  let token = await getAccessToken();
  let response = await fetch(url, {
    ...options,
    headers: {
      ...options.headers,
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json',
      'Accept': 'application/json',
    }
  });

  // If the token is expired, attempt to refresh and retry the request
  if (response.status === 401) {
    console.log('401 API call to ', url, 'try silent signin');
    try {
      // Attempt to renew the token
      const user = await userManager.signinSilent();
      console.log('Silent signin', user);
      token = user.id_token;

      // Retry the request with the new token
      response = await fetch(url, {
        ...options,
        headers: {
          ...options.headers,
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        },
      });
    } catch (error) {
      console.error('Token renewal failed:', error, error.message.includes('login_required'));

      // If silent renewal fails with `login_required`, redirect to login
      if (error.message.includes('login_required')) {
        console.log('Token renewal forward to login page');
        const router = useRouter();  // Get the router instance for redirection
        router.push({
          path: '/login',
          query: { returnUrl: window.location.pathname }, // Redirect back to the original path after login
        });
      } else {
        throw error; // Re-throw other errors
      }
    }
  }

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  return response.json();
}