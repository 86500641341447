import { createI18n } from 'vue-i18n'
import { lang, langs } from '@/utils/store';

function loadLocaleMessages() {

  const locales = import.meta.glob('@/locales/??.json')
  const messages = {}

  for (const path in locales) {
    locales[path]().then((module) => {
      const locale = path.match(/.*\/([a-z][a-z])\.json/)
      // console.log(path, module, locale)
      if (locale?.length > 1) {
        messages[ locale[1] ] = module
        langs.push(locale[1]);
      }
    })
  }
  return messages
}

export default createI18n({
  legacy: false,
  mode: 'composition',
  allowComposition: true,
  globalInjection: true,
  locale: lang.value || import.meta.env.VUE_APP_I18N_LOCALE || 'bg',
  fallbackLocale: import.meta.env.VUE_APP_I18N_FALLBACK_LOCALE || 'bg',
  messages: loadLocaleMessages()
})
