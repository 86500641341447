// src/oidc-client.js
import { UserManager } from 'oidc-client';
import { oidcSettings } from './oidc-config';
import { useRouter } from 'vue-router';
import Oidc from 'oidc-client';

const router = useRouter();

// Initialize and export the UserManager instance
export const userManager = new UserManager(oidcSettings);

export function signIn({ state } = {}) {
  userManager.signinRedirect({ state });
}

userManager.events.addUserLoaded((user) => {
  const returnUrl = user.state && user.state.returnUrl ? user.state.returnUrl : '/';
  router.push(returnUrl);
});

export function signOut() {
  userManager.signoutRedirect();
}

export function getUser() {
  return userManager.getUser();
}

export async function getAccessToken() {
  const user = await getUser();
  return user ? user.id_token : null;
}

Oidc.Log.logger = console;
Oidc.Log.level = Oidc.Log.ERROR;

userManager.events.addUserLoaded(function (user) {
  console.log('New User Loaded：', arguments);
  console.log('Acess_token: ', user.id_token)
});