import { apiCall } from '../api'
import { loadConfig } from '@/config';

const config = await loadConfig();

const CHANGES_HEARTBEAT = config.data.CHANGES_HEARTBEAT;
const CHANGES_TIMEOUT = config.data.CHANGES_TIMEOUT;
const AFTER_POLL_TIMEOUT = config.data.AFTER_POLL_TIMEOUT;

const DATA_URLS = config.data.source;

/**
 * Get catalog data of specified type
 * @param {string} table type of catalog data {vehicle, driver, order, repair}
 * @param {string} query optional native query string
 * @param {number} offset optional index of start row (0-based)
 * @param {number} limit optional max muber of rows per page
 * @returns {Promise} promise that provides success (status 200-299) result data:
 * {
 *    lines {array}  current page data,
 *    total {number} total rows count,
 *    offset {number} page start,
 *    limit {number} page size
 * }
 * @throws {Error} error object with message in case if no success (status not in 200-299)
 */
export async function getCatalogData(table, query = null, offset = 0, limit = 1000) {
  const json = await apiCall(DATA_URLS[table].url + (query ? '&where=' + query : '') + '&offset=' + offset + '&limit=' + limit, {
    method: DATA_URLS[table].method,
  });
  return {
        lines: json.list,
        total: json.pageInfo.totalRows,
        offset,
        limit
      };
}

/**
 * Get all catalog data of specified type
 * @param {string} table type of catalog data {vehicle, driver, order, repair}
 * @param {string} query optional native query string
 * @param {number} limit max muber of rows per page
 * @returns {Promise} promise that provides success (status 200-299) result data array with all cata
 * @throws {Error} error object with message in case if no success (status not in 200-299)
 */
export async function getAllCatalogData(table, query, limit) {
  const result = [];
  let offset = 0;
  let total = 0;
  do {
    const page = await getCatalogData(table, query, offset, limit);
    total = page.total;
    offset += page.lines.length;
    result.push(...page.lines);
  }
  while (result.length < total);
  return result;
}

/**
 * Get tasks for given catalog object and time interval
 * @param {string} key optional foreign key name {vehicle_id, driver_id, order_id, course_id}
 * @param {string} value optional foreign key value (Id of the catalog object)
 * @param {cat} cat optional task category, e.g. 'order', 'vehicle', 'driver', 'action'
 * @param {date} after filter tasks after given date
 * @param {date} until filter tasks until given date
 * @param {number} limit max muber of rows per page
 * @param {string} bookmark bookmark for next page (always returned, to detect if last page is received check lines.length < limit)
 * @returns {Promise} promise that provides success (status 200-299) result data:
 * {
 *    lines {array}  current page data,
 *    limit {number} page size,
 *    bookmark {string} bookmark for next page (always returned, to detect if last page is received check lines.length < limit)
 * }
 * @throws {Error} error object with message in case if no success (status not in 200-299)
 */
export async function getTasks(key, value, cat, after, until, limit = 1000, bookmark) {
  const body = {
    selector: {
        after: {
          '$lt': until
        },
        until: {
          '$gt': after
        }
    },
    sort: [{'after': 'asc'}, {'until': 'asc'}],
    limit
  };
  if (key && value) {
    body.selector[key] = typeof value === 'number' ? value.toString() : value;
  }
  if (cat) {
    body.selector.cat = cat;
  }
  if (bookmark) {
    body.bookmark = bookmark;
  }

  const json = await apiCall(DATA_URLS.taskSearch.url, {
    method: DATA_URLS.taskSearch.method,
    body: JSON.stringify(body)
  });

  return {
    lines: json.docs,
    limit,
    bookmark: json.bookmark
  };
}

/**
 * Get all tasks for given catalog object and time interval
 * @param {string} key optional foreign key name {vehicle_id, driver_id, order_id, course_id}
 * @param {string} value optionl foreign key value (Id of the catalog object)
 * @param {cat} cat optional task category, e.g. 'order', 'vehicle', 'driver', 'action' * @param {date} after filter tasks after given date
 * @param {date} until filter tasks until given date
 * @param {number} limit max muber of rows per page
 * @returns {Promise} promise that provides success (status 200-299) result array with all data
 * @throws {Error} error object with message in case if no success (status not in 200-299)
 */
export async function getAllTasks(key, value, cat, after, until, limit) {
  const result = [];
  let page;
  let bookmark = null;
  do {
    page = await getTasks(key, value, cat, after, until, limit, bookmark);
    bookmark = page.bookmark;
    result.push(...page.lines);
  }
  while(page.lines.length === page.limit);
  return result;
}

/**
 * Create a new or update an existing task
 * @param {object} task task to save. If task is already saved it must have _id and _rev properties
 * @returns {Promise} promise that provides success (status 200-299) result data:
 * {
 *    _id {string} task id, it have to be added to task object
 *    _rev {string} task revision number, it have to be added/updated to task object
 * }
 * @throws {Error} error object with message in case if no success (status not in 200-299)
 */
export async function saveTask(task) {
  if (!task)
    return;

  const dataURL = !task._id ? DATA_URLS.taskCreate : DATA_URLS.taskUpdate;

  const body = JSON.stringify(task);

  const json = await apiCall(dataURL.url + (!task._id ? '' : '/' + task._id), {
      method: dataURL.method,
      body
    });

  return {
    '_id': json.id,
    '_rev': json.rev
  };
}

/**
 * Bulk create / update / delete of many tasks
 * @param {array} tasks tasks to create/update/delete. If task is already saved it must have _id and _rev properties, to mark deletion _deleted=true must be added
 * @returns {Promise} promise that provides success (status 200-299) result data:
 * [{
 *    _id {string} task id, it have to be added to task object,
 *    _rev {string} task revision number, it have to be added/updated to task object,
 *    error {string} error code if no success: [conflict | forbiden]
 *    reason {string} error reason
 * },
 * ...
 * ]
 * @throws {Error} error object with message in case if no success (status not in 200-299)
 */
export async function saveOrDeleteTasks(tasks) {
  if (!tasks?.length) {
    console.log('saveOrDeleteTasks: no tasks sepcified');
    return;
  }

  const body = JSON.stringify({docs: tasks});

  const json = await apiCall(DATA_URLS.taskBulk.url, {
      method: DATA_URLS.taskBulk.method,
      body
    });

  const results = [];
  json.forEach(item => {
    const result = {
      '_id': json.id,
      '_rev': json.rev
    };
    if (item.error) {
      result.error = item.error;
      result.reason = item.reason;
    }
    results.push(result);
  });
  return results;
}

/**
 * Delete an existing task with specified _id and _rev
 * @returns {Promise} promise that provides success (status 200-299) result data:
 * {
 *    _id {string} task id
 *    _rev {string} task revision number
 * }
 * @throws {Error} error object with message in case if no success (status not in 200-299)
 */
export async function deleteTask(task) {
  if (!task?._id || !task?._rev) {
    return;
  }

  const json = await apiCall(DATA_URLS.taskDelete.url + '/' + task._id + '?rev=' + task._rev , {
    method: DATA_URLS.taskDelete.method,
  });
  return {
    '_id': json.id,
    '_rev': json.rev
  };
}

let changeListener;
let abortController;

function cancel() {
  if (abortController) {
    console.log("Abort change listener");
    abortController.abort();
  }
}

/**
 * Add change listener that will receive changes of tasks. Listening statrts on adding the first listener
 * and could be stopped by calling abortController.abort() function
 * @param {function|object} listener callback function or object having function onChange()
 * @param {string} since sequence of the last event, special value 'now', or 0 - from the begining
 */
export function setChangeListener(listener, since = 'now') {
  if (changeListener === listener) {
    //listener already set
    return;
  }
  else {
    cancel();
  }
  changeListener = listener;

  if (changeListener) {
    // console.log('Start polling for task changes');
    poll(since);
  }
}

function poll(since) {
  abortController = new AbortController();
  const signal = abortController.signal;
  apiCall(DATA_URLS.taskChanges.url
    + '?feed=longpoll'
    + '&include_docs=true'
    + '&heartbeat='+ CHANGES_HEARTBEAT
    + '&timeout=' + CHANGES_TIMEOUT
    + '&since=' + since
    , {
    method: DATA_URLS.taskChanges.method,
    signal
  })
  .then((json) => {
    since = json?.last_seq || 'now';
    if (json?.results) {
      notifyChangeListener(json?.results, since);
    }
    setTimeout(() => poll(since), AFTER_POLL_TIMEOUT);
  })
  .catch(err =>{
    console.log('Error when notifying change listener', err);
    setTimeout(() => poll(since), AFTER_POLL_TIMEOUT);
    // throw new Error(`Error when notifying change listener: ${err}`);
  });
}

function notifyChangeListener(results, since) {
  // console.log('notifyChangeListener', results);
  if (!results) {
    return;
  }
  const docs = results.map(result => result.doc);
  if (typeof changeListener === 'function') {
    changeListener(docs, since);
  }
  else if (typeof changeListener === 'object' && typeof changeListener.onChange === 'function') {
    changeListener.onChange(docs, since)
  }
}

export async function getUuids(count = 1) {
  const json = await apiCall(DATA_URLS.uuids.url
    + '?count=' + count
    , {
    method: DATA_URLS.uuids.method,
  });
  return json.uuids;
}
