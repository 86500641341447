// Plugins
import { registerPlugins } from '@/plugins'

// Components
import App from './App.vue'
// import App from "./GanttPlayground.vue"
import ganttastic from "./vue-ganttastic.js"

// Composables
import { createApp } from 'vue'

// Sentry remote logging
import * as Sentry from "@sentry/vue";

const app = createApp(App)

Sentry.init({
  app,
  release: "synergizer-planner@" + process.env.SYN_APP_VERSION,
  environment: process.env.NODE_ENV,
  dsn: "https://18ef5b1e37ddc32d719161ecbb0bf5d3@o4507826055020544.ingest.de.sentry.io/4507826057576528",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/ncd\.synergizer\.bg\//],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

registerPlugins(app)

app.use(ganttastic)
app.mount('#app')
