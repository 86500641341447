export const routes = [
  {
    path: '/',
    name: '/',
    component: () => import('/src/src/pages/index.vue'),
    /* no children */
  },
  {
    path: '/Callback',
    name: '/Callback',
    component: () => import('/src/src/pages/Callback.vue'),
    /* no children */
  },
  {
    path: '/Login',
    name: '/Login',
    component: () => import('/src/src/pages/Login.vue'),
    /* no children */
  },
  {
    path: '/Logout',
    name: '/Logout',
    component: () => import('/src/src/pages/Logout.vue'),
    /* no children */
  },
  {
    path: '/Renew',
    name: '/Renew',
    component: () => import('/src/src/pages/Renew.vue'),
    /* no children */
  }
]
