import type * as CSS from "csstype"

type Color = CSS.DataType.Color

export type ColorScheme = {
  primary: Color
  secondary: Color
  ternary: Color
  quartenary: Color
  hoverHighlight: Color
  markerCurrentTime: Color
  text: Color
  background: Color
  toast?: Color
}

export const colorSchemes: Record<string, ColorScheme> = {
  default: {
    primary: "#eeeeee",
    secondary: "#E0E0E0",
    ternary: "#F5F5F5",
    quartenary: "#ededed",
    hoverHighlight: "rgba(204, 216, 219, 0.5)",
    markerCurrentTime: "rgba(0, 0, 0, 0.5)",
    text: "#404040",
    background: "white"
  },


  "material-blue": {
    primary: "#005eb8",
    secondary: "#80BD00",
    ternary: "#F5F5F5",
    quartenary: "#ededed",
    hoverHighlight: "rgba(204, 216, 219, 0.5)",
    markerCurrentTime: "#257ed1",
    text: "#404040",
    background: "white"
  },

}

export type ColorSchemeKey = keyof typeof colorSchemes

export default colorSchemes
